import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 140 140"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M70,140a70,70,0,1,1,70-70A70,70,0,0,1,70,140ZM87.308,98C80.827,98,76.287,94.431,75,88.667H65C63.713,94.431,59.173,98,52.692,98,44.983,98,40,92.962,40,85.167s4.983-12.833,12.692-12.833a15.686,15.686,0,0,1,3.122.313L60.693,64.1a12.961,12.961,0,0,1-3.386-9.262C57.308,47.038,62.29,42,70,42s12.692,5.038,12.692,12.833a12.947,12.947,0,0,1-3.418,9.3l4.864,8.526a15.653,15.653,0,0,1,3.169-.323c7.71,0,12.692,5.038,12.692,12.833S95.017,98,87.308,98ZM52.692,79.333c-3.882,0-5.769,1.908-5.769,5.833S48.81,91,52.692,91s5.769-1.908,5.769-5.833S56.574,79.333,52.692,79.333ZM70,49c-3.882,0-5.769,1.908-5.769,5.833S66.118,60.667,70,60.667s5.769-1.908,5.769-5.833S73.882,49,70,49Zm3.121,18.354a15.723,15.723,0,0,1-6.289-.01L61.968,75.87A11.851,11.851,0,0,1,65,81.667H75a11.859,11.859,0,0,1,3-5.761ZM87.308,79.333c-3.882,0-5.769,1.908-5.769,5.833S83.426,91,87.308,91s5.769-1.908,5.769-5.833S91.19,79.333,87.308,79.333Z"
      />
    </svg>
  )
})
