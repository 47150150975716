import { graphql, navigate } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import Link from "src/utils/Link"
import * as styles from "./styles.module.scss"
import BlogThumbnail from "src/components/BlogThumbnail"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import Remote from "src/components/Icons/CategoryRemote"
import Productivity from "src/components/Icons/CategoryProductivity"
import Leadership from "src/components/Icons/CategoryLeadership"
import Updates from "src/components/Icons/CategoryUpdates"
import Stories from "src/components/Icons/CategoryStories"
import Teamwork from "src/components/Icons/CategoryTeamwork"
import BlogSubFooter from "src/components/BlogSubFooter"
import GetStarted from "src/components/Banners/GetStarted"
import { IconProps } from "src/components/Update/types"
import SubNavBlog from "src/components/SubNavBlog"
import BlogArticles from "src/components/BlogArticles"

const Icon: FC<IconProps> = ({ icon }) => {
  // noinspection JSRedundantSwitchStatement
  switch (icon) {
    case "Productivity":
      return (
        <Productivity fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Productivity>
      )
    case "Leadership":
      return (
        <Leadership fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Leadership>
      )
    case "Updates":
      return (
        <Updates fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Updates>
      )
    case "Remote":
      return (
        <Remote fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Remote>
      )
    case "Re:work":
      return (
        <Stories fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Stories>
      )
    case "Ask-Flow":
      return (
        <Stories fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Stories>
      )
    case "TeamWork":
      return (
        <Teamwork fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Teamwork>
      )
    default:
      return (
        <Teamwork fill="url(#badgeGrad)" className={styles.categLogo}>
          <defs>
            <linearGradient id="badgeGrad" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#00103d" />
              <stop offset="100%" stopColor="#001652" />
            </linearGradient>
          </defs>
        </Teamwork>
      )
  }
}

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
    allWordpressPost: { nodes },
    allWordpressCategory: { nodes: categories },
  },
}) => {
  const { currentPath, getPage } = usePages()
  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <SubNavBlog categories={categories} />
      <div className={`content-wrapper ${styles.postsContent}`}>
        <h1 className={styles.pageTitle}>{texts.title}</h1>
        <p className={styles.description}>{texts.description}</p>
        <div
          className={`${styles.post} ${styles.main}`}
          onClick={() => navigate(`${currentPath}/${nodes[0].slug}`)}
        >
          <div className={styles.imgContent}>
            <BlogThumbnail
              url={
                (nodes[0].acf && nodes[0].acf.thumbnail) ||
                nodes[0].jetpack_featured_media_url
              }
            />
          </div>
          <div className={styles.infoContent}>
            <p className={styles.info}>
              {nodes[0].categories.map((categ) => (
                <Link
                  to={`/blog/category/${categ.slug}`}
                  className={styles.categorie}
                  onClick={(evt) => evt.stopPropagation()}
                  dangerouslySetInnerHTML={{ __html: categ.name }}
                />
              ))}
            </p>
            <Link
              to={`/blog/${nodes[0].slug}`}
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: nodes[0].title }}
            />
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: nodes[0].content }}
            />
            <span className={styles.date}>{nodes[0].date}</span>
          </div>
        </div>
        <div className={styles.postList}>
          {nodes.slice(1, 3).map((node) => (
            <div
              key={node.slug}
              className={styles.post}
              onClick={() => navigate(`${currentPath}/${node.slug}`)}
            >
              <div className={styles.imgContent}>
                <BlogThumbnail
                  url={
                    (node.acf && node.acf.thumbnail) ||
                    node.jetpack_featured_media_url
                  }
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.infoContent}>
                <p className={styles.info}>
                  {node.categories.map((categ: any) => (
                    <Link
                      key={categ.slug}
                      to={`/blog/category/${categ.slug}`}
                      className={styles.categorie}
                      onClick={(evt) => evt.stopPropagation()}
                      dangerouslySetInnerHTML={{ __html: categ.name }}
                    />
                  ))}
                </p>
                <Link
                  to={`/blog/${node.slug}`}
                  className={styles.title}
                  dangerouslySetInnerHTML={{ __html: node.title }}
                />
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{ __html: node.content }}
                />
                <span className={styles.date}>{node.date}</span>
              </div>
            </div>
          ))}
        </div>
        <Link
          to={getPage("blog/all").url}
          className={`button button-second ${styles.button}`}
        >
          {texts.morePosts}
        </Link>
        <div className={styles.categTitleContainer}>
          <div className={styles.categLine} />
          <span className={styles.categTitle}>{texts.categories}</span>
        </div>
        <div className={styles.categContainer}>
          {categories.map((cat: any) => (
            <Link
              to={`/blog/category/${cat.slug}`}
              className={styles.categLink}
            >
              <Icon icon={cat.name} />
              <span className={styles.categName}>{cat.name}</span>
              <p
                className={styles.categDescription}
                dangerouslySetInnerHTML={{ __html: cat.description }}
              />
            </Link>
          ))}
        </div>
      </div>

      <BlogArticles nodes={nodes} />

      <div className={`content-wrapper ${styles.whatflowContainer}`}>
        <BlogSubFooter />
        <GetStarted className={styles.banner} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        header
        allPosts
        metaTitle
        metaDescription
        title
        description
        morePosts
        categories
        latestArticles
        popularArticles
      }
    }
    allWordpressPost(limit: 13) {
      nodes {
        slug
        title
        author {
          name
        }
        content
        categories {
          name
          slug
        }
        date(formatString: "MMMM D, YYYY")
        jetpack_featured_media_url
        acf {
          thumbnail
        }
      }
    }

    allWordpressCategory(filter: { name: { ne: "Uncategorized" } }) {
      nodes {
        name
        slug
        description
      }
    }
  }
`

export default Template
