import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 140 140"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M70,140a70,70,0,1,1,70-70A70,70,0,0,1,70,140Zm31.518-70,3.258,4.912A1.345,1.345,0,0,1,103.664,77h-7.6a1.738,1.738,0,0,1-1.449-.779l-1.288-1.941a1.739,1.739,0,0,0-1.449-.779H73.661v4.083a1.746,1.746,0,0,1-1.741,1.75H71a3.469,3.469,0,0,1-1.931-.588L58.187,71.456a1.755,1.755,0,0,1,0-2.912l10.878-7.289A3.469,3.469,0,0,1,71,60.667H71.92a1.746,1.746,0,0,1,1.741,1.75V66.5h18.22a1.739,1.739,0,0,0,1.449-.779l1.288-1.941A1.739,1.739,0,0,1,96.066,63h7.6a1.345,1.345,0,0,1,1.112,2.088ZM91.961,59.5H88.184a2.563,2.563,0,0,1-2.236-1.279C82.594,52.312,76.341,49,67.857,49c-13.083,0-20.893,7.85-20.893,21s7.81,21,20.893,21c8.484,0,14.737-3.312,18.092-9.221A2.563,2.563,0,0,1,88.184,80.5h3.777a1.435,1.435,0,0,1,1.432,1.439,1.506,1.506,0,0,1-.117.583C89.2,92.188,80.125,98,67.857,98,50.936,98,40,87.008,40,70S50.936,42,67.857,42c12.268,0,21.34,5.812,25.419,15.478a1.505,1.505,0,0,1,.117.583A1.435,1.435,0,0,1,91.961,59.5Z"
      />
    </svg>
  )
})
