import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 140 140"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M70,140a70,70,0,1,1,70-70A70,70,0,0,1,70,140ZM54.391,46.308V43.615A1.607,1.607,0,0,0,52.794,42H49.6A1.607,1.607,0,0,0,48,43.615V96.385A1.607,1.607,0,0,0,49.6,98h3.2a1.607,1.607,0,0,0,1.6-1.615V82.923H69.3v4.846a1.607,1.607,0,0,0,1.6,1.615H95.4A1.607,1.607,0,0,0,97,87.769V54.385a1.607,1.607,0,0,0-1.6-1.615H75.7V47.923a1.607,1.607,0,0,0-1.6-1.615H54.391Zm0,6.461V76.462H69.3V52.769H54.391Zm36.217,6.462H75.7V82.923H90.609V59.231Z"
      />
    </svg>
  )
})
