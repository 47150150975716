import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 140 140"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M70,140a70,70,0,1,1,70-70A70,70,0,0,1,70,140ZM96.25,66.333H81.328c2.293-9.165,8.723-12.721,11.1-13.28a1.2,1.2,0,0,0,.9-1.189V51.2a1.878,1.878,0,0,0-.513-1.3l-1.308-1.371A1.711,1.711,0,0,0,90.275,48C82.931,48,73.5,58.263,73.5,71.222V90.167A1.793,1.793,0,0,0,75.25,92h21A1.793,1.793,0,0,0,98,90.167v-22A1.793,1.793,0,0,0,96.25,66.333ZM91,84.666H80.5v-11H91v11ZM64.75,66.333H49.828c2.293-9.165,8.723-12.721,11.1-13.28a1.2,1.2,0,0,0,.9-1.189V51.2a1.878,1.878,0,0,0-.513-1.3l-1.308-1.371A1.711,1.711,0,0,0,58.775,48C51.431,48,42,58.263,42,71.222V90.167A1.793,1.793,0,0,0,43.75,92h21a1.793,1.793,0,0,0,1.75-1.833v-22A1.793,1.793,0,0,0,64.75,66.333ZM59.5,84.666H49v-11H59.5v11Z"
      />
    </svg>
  )
})
