import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, fill, ...props }) => {
  return (
    <svg
      fill={fill ? fill : "currentColor"}
      viewBox="0 0 140 140"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
      <path
        className="cls-1"
        fillRule="evenodd"
        d="M70,140a70,70,0,1,1,70-70A70,70,0,0,1,70,140Zm0-42C52.992,98,42,87.008,42,70S52.992,42,70,42,98,52.992,98,70,87.008,98,70,98ZM88.8,80.5H82.145a39.955,39.955,0,0,1-2.1,8.553A17.212,17.212,0,0,0,88.8,80.5ZM70,91c1.673,0,3.911-3.4,5.067-10.5H64.933C66.089,87.6,68.327,91,70,91ZM59.953,89.053a39.955,39.955,0,0,1-2.1-8.553H51.2A17.212,17.212,0,0,0,59.953,89.053ZM49.207,66.5a29.707,29.707,0,0,0,0,7h8.035c-0.045-1.136-.076-2.3-0.076-3.5s0.03-2.364.076-3.5H49.207Zm1.989-7h6.659a39.953,39.953,0,0,1,2.1-8.553A17.213,17.213,0,0,0,51.2,59.5ZM70,49c-1.673,0-3.911,3.4-5.067,10.5H75.068C73.911,52.4,71.673,49,70,49Zm5.751,17.5h-11.5c-0.048,1.118-.082,2.27-0.082,3.5s0.034,2.382.082,3.5h11.5c0.048-1.118.082-2.27,0.082-3.5S75.8,67.618,75.751,66.5Zm4.3-15.553a39.953,39.953,0,0,1,2.1,8.553H88.8A17.213,17.213,0,0,0,80.047,50.947ZM90.793,66.5H82.758c0.045,1.136.076,2.3,0.076,3.5s-0.03,2.364-.076,3.5h8.035A29.707,29.707,0,0,0,90.793,66.5Z"
      />
    </svg>
  )
})
